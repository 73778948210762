import React, { useState } from "react";
import { EditTextPill } from "../HeaderCommon.js";

function TextPanel(props) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div
        className="EditorDrawerItemHeader"
        onClick={() => setOpen(!open)}
      >
        <span className="PanelOpenStatus">{open ? "▾" : "▸"}</span>
        <span>Text</span>
      </div>
      {open ? <TextDrawer {...props} /> : null}
    </div>
  );
}

function TextDrawer(props) {
  const text = props.text;

  const addText = (newLine) => {
    Inventum.labels.addText(props.id, newLine);
    props.getLabels();
  };

  const editText = (index, newLine) => {
    Inventum.labels.editText(props.id, index, newLine);
    props.getLabels();
  };

  const clearText = (index) => {
    Inventum.labels.clearTextLine(props.id, index);
    props.getLabels();
  };

  return (
    <div>
      <div className="EditorTextButtonsContainer">
        <div className="PanelKeyCell">Add Line</div>
        <EditTextPill
          setText={addText}
          id={props.id}
          label="+"
          title="Add a new line"
          value=""
        />
      </div>

      <div className="EditorLabelTextList">
        {text.map((line, index) => (
          <span
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              marginBottom: "5px",
              marginRight: "5px",
              padding: "4px",
              borderRadius: "5px",
              backgroundColor: "#5b5b5b",
            }}
          >
            <EditTextPill
              setText={(newValue) => editText(index, newValue)}
              key={index}
              value={line}
              label={line}
            />
            <div
              title="Delete Text"
              className="TextPillDeleteButton"
              onClick={(e) => {
                e.stopPropagation();
                clearText(index);
              }}
            >
              <i
                style={{ fontSize: "14px" }}
                className="material-icons notranslate"
              >
                delete
              </i>
            </div>
          </span>
        ))}
      </div>
    </div>
  );
}

export { TextPanel };
