import * as THREE from "three";

class LabelMesh {
  constructor(styleOpts) {
    let style = {
      backgroundColor: "#333333",
      depthTest: false,
    };

    style = Object.assign(style, styleOpts);

    var geometry = new THREE.BufferGeometry();
    const verts = new Float32Array(3 * 3);
    const backgroundColor = style.backgroundColor;

    geometry.setAttribute("position", new THREE.BufferAttribute(verts, 3));
    geometry.setIndex(new THREE.BufferAttribute(new Uint32Array([0, 1, 2]), 1));
    // geometry.faces.push(new THREE.Face3(0, 1, 2));
    geometry.computeBoundingSphere();

    var material = new THREE.MeshBasicMaterial({ color: 0xffffff, side: THREE.DoubleSide });

    material.color.setStyle(backgroundColor);
    this.pointer = new THREE.Mesh(geometry, material);
  }

  updatePointer() {
    // Calculates a midpoint percentage between two vec3s
    function calcOffset(pointA, pointB, percentage) {
      var dir = pointB.clone().sub(pointA);
      var len = dir.length();
      dir = dir.normalize().multiplyScalar(len * percentage);
      return pointA.clone().add(dir);
    }

    const pointerMesh = this.pointer;
    pointerMesh.updateMatrixWorld();
    const backboard = pointerMesh.children[0];
    // Calculates the center of the billboard world position.
    const backboardWorldPosition = backboard.position.clone().applyMatrix4(pointerMesh.matrixWorld);

    // Get the world positions of the 4 backboard vertices.
    // IMPORTANT!! Local is NOT the local of the backboard. It's the LOCAL of the parent object pointer
    const backboardVerts = backboard.geometry.attributes.position.array;
    const vertA = new THREE.Vector3(backboardVerts[0], backboardVerts[1], backboardVerts[2]);
    const vertB = new THREE.Vector3(backboardVerts[3], backboardVerts[4], backboardVerts[5]);
    const vertC = new THREE.Vector3(backboardVerts[6], backboardVerts[7], backboardVerts[8]);
    const vertD = new THREE.Vector3(backboardVerts[9], backboardVerts[10], backboardVerts[11]);

    var tLeftLocal = pointerMesh.worldToLocal(vertA.clone().applyMatrix4(backboard.matrixWorld));
    // var tLeftWorld = tLeftLocal.clone().applyMatrix4(backboard.matrixWorld);

    var tRightLocal = pointerMesh.worldToLocal(vertB.clone().applyMatrix4(backboard.matrixWorld));
    // var tRightWorld = tRightLocal.clone().applyMatrix4(backboard.matrixWorld);

    var bLeftLocal = pointerMesh.worldToLocal(vertC.clone().applyMatrix4(backboard.matrixWorld));
    // var bLeftWorld = bLeftLocal.clone().applyMatrix4(backboard.matrixWorld);

    var bRightLocal = pointerMesh.worldToLocal(vertD.clone().applyMatrix4(backboard.matrixWorld));
    // var bRightWorld = bRightLocal.clone().applyMatrix4(backboard.matrixWorld);

    var backboardLocalPosition = pointerMesh.worldToLocal(backboardWorldPosition.clone());

    var offset = {};
    var offset2 = {};

    // Calculate where to attach the pointer to the blackboard based on several properties (above/below, angle etc)
    let attachPoint = "";
    // Figure out which side of the billboard is closer to the anchor. Left or Right
    // pointerMesh.position.distanceTo(tLeft) < pointerMesh.position.distanceTo(tRight) ? attachPoint = "LEFT" : attachPoint = "RIGHT";
    Math.abs(tLeftLocal.x) < Math.abs(tRightLocal.x) ? (attachPoint = "LEFT") : (attachPoint = "RIGHT");
    // Calculate the distance between the anchor and the center of the billboard for calculating the angle.
    // let deltaVec = pointerMesh.position.clone().sub(backboardWorldPosition);
    const deltaVec = backboardLocalPosition;
    // Attach to the top/bottom if the y value is bigger than the X/Z value
    if (Math.abs(deltaVec.y) > Math.abs(deltaVec.x) && Math.abs(deltaVec.y) > Math.abs(deltaVec.z)) {
      // Calculate attach point based on above or below
      backboardLocalPosition.y > 0 ? (attachPoint += "_BELOW") : (attachPoint += "_ABOVE");
    } else {
      // Calculate attach point based on above or below
      backboardLocalPosition.y > 0 ? (attachPoint += "_BELOW_SIDE") : (attachPoint += "_ABOVE_SIDE");
    }

    const offsetPercent = 0.3;

    const updateVert = (offset, pos) => {
      const arr = pointerMesh.geometry.attributes.position.array;
      arr[offset * 3 + 0] = pos.x;
      arr[offset * 3 + 1] = pos.y;
      arr[offset * 3 + 2] = pos.z;
      pointerMesh.geometry.attributes.position.needsUpdate = true;
    };

    // May be useful. Reference         //pointerMesh.geometry.vertices[1].copy(pointerMesh.worldToLocal(offset));

    // Based on the attach value move the pointer vertices to the correct spot.
    switch (attachPoint) {
      case "LEFT_ABOVE":
        offset = calcOffset(tLeftLocal, tRightLocal, offsetPercent);
        offset2 = calcOffset(tLeftLocal, tRightLocal, offsetPercent * 1.8);
        updateVert(1, offset);
        updateVert(2, offset2);
        break;
      case "RIGHT_ABOVE":
        offset = calcOffset(tRightLocal, tLeftLocal, offsetPercent);
        offset2 = calcOffset(tRightLocal, tLeftLocal, offsetPercent * 1.8);
        updateVert(1, offset2);
        updateVert(2, offset);
        break;
      case "LEFT_BELOW":
        offset = calcOffset(bLeftLocal, bRightLocal, offsetPercent);
        offset2 = calcOffset(bLeftLocal, bRightLocal, offsetPercent * 1.8);
        updateVert(1, offset);
        updateVert(2, offset2);
        break;
      case "RIGHT_BELOW":
        offset = calcOffset(bRightLocal, bLeftLocal, offsetPercent);
        offset2 = calcOffset(bRightLocal, bLeftLocal, offsetPercent * 1.8);
        updateVert(1, offset2);
        updateVert(2, offset);
        break;
      case "LEFT_ABOVE_SIDE":
        offset = calcOffset(tLeftLocal, bLeftLocal, offsetPercent);
        offset2 = calcOffset(tLeftLocal, bLeftLocal, offsetPercent * 1.8);
        updateVert(1, offset);
        updateVert(2, offset2);
        break;
      case "LEFT_BELOW_SIDE":
        offset = calcOffset(bLeftLocal, tLeftLocal, offsetPercent);
        offset2 = calcOffset(bLeftLocal, tLeftLocal, offsetPercent * 1.8);
        updateVert(1, offset);
        updateVert(2, offset2);
        break;
      case "RIGHT_ABOVE_SIDE":
        offset = calcOffset(tRightLocal, bRightLocal, offsetPercent);
        offset2 = calcOffset(tRightLocal, bRightLocal, offsetPercent * 1.8);
        updateVert(1, offset);
        updateVert(2, offset2);
        break;
      case "RIGHT_BELOW_SIDE":
        offset = calcOffset(bRightLocal, tRightLocal, offsetPercent);
        offset2 = calcOffset(bRightLocal, tRightLocal, offsetPercent * 1.8);
        updateVert(1, offset);
        updateVert(2, offset2);
        break;
      default:
        console.log("Should never get here!");
    }
    // Update the geometry vertices.
    pointerMesh.geometry.verticesNeedUpdate = true;
  }

  getBackboardPosition() {
    return this.pointer.children[0].position.clone();
  }

  getScale() {
    return this.pointer.scale.x;
  }

  setScale(scale) {
    this.pointer.scale.setScalar(scale);
  }

  getRotation() {
    this.pointer.updateMatrixWorld();
    return this.pointer.quaternion.toArray();
  }

  setRotation() {}

  getMesh() {
    return this.pointer;
  }

  setPointerVisible(isVisible) {
    this.pointer.visible = isVisible;
  }
}

export { LabelMesh };
