// PenToolContainer.js
import React from "react";

// Optional: a helper function to scale from [0..1] to the canvas size
const valFromPercent = (percent, total) => {
  return Math.floor(parseFloat(percent) * parseInt(total));
};

class PenToolContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      canvasState: {}, // local lines, if you want to store them
      activeLinePoints: [],
      mouseDown: false,
      penColor: "#18636e",
      penSize: 5,
      penEnabled: false,
    };
    this.canvasRef = React.createRef();
    this.memCanvas = null; // Offscreen buffer for smoothing

    // Binds
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.undoLast = this.undoLast.bind(this);
    this.togglePen = this.togglePen.bind(this);
    this.setPenColor = this.setPenColor.bind(this);
    this.setPenSize = this.setPenSize.bind(this);
  }

  componentDidMount() {
    // Build an offscreen memory canvas for line smoothing
    this.memCanvas = document.createElement("canvas");
    this.memCanvas.width = this.props.width || window.innerWidth;
    this.memCanvas.height = this.props.height || window.innerHeight;

    // Main drawing canvas
    this.canvasRef.current.width = this.props.width || window.innerWidth;
    this.canvasRef.current.height = this.props.height || window.innerHeight;
  }

  componentDidUpdate(prevProps) {
    // If the parent changed the width or height,
    // reset our canvases and re-draw lines
    if (prevProps.width !== this.props.width || prevProps.height !== this.props.height) {
      this.canvasRef.current.width = this.props.width;
      this.canvasRef.current.height = this.props.height;
      this.memCanvas.width = this.props.width;
      this.memCanvas.height = this.props.height;

      this.drawAllSavedLines();
    }
  }

  handleMouseDown(e) {
    if (!this.state.penEnabled) return;
    this.setState({ mouseDown: true, activeLinePoints: [] });
  }

  handleMouseUp(e) {
    if (!this.state.penEnabled) return;
    // The line is finished
    let ctxMem = this.memCanvas.getContext("2d");
    ctxMem.clearRect(0, 0, this.memCanvas.width, this.memCanvas.height);
    ctxMem.drawImage(this.canvasRef.current, 0, 0);

    // If you want to store lines in local state:
    let lineInfo = {
      points: this.state.activeLinePoints,
      color: this.state.penColor,
      size: this.state.penSize,
    };
    // example: put them in 'NO_SOCKET_MODE' key
    let newCanvasState = { ...this.state.canvasState };
    if (!newCanvasState["NO_SOCKET_MODE"]) {
      newCanvasState["NO_SOCKET_MODE"] = [];
    }
    newCanvasState["NO_SOCKET_MODE"].push(lineInfo);

    this.setState({
      mouseDown: false,
      activeLinePoints: [],
      canvasState: newCanvasState,
    });
  }

  handleMouseMove(e) {
    if (!this.state.penEnabled || !this.state.mouseDown) return;
    let canvas = this.canvasRef.current;
    let ctx = canvas.getContext("2d");

    // Clear the canvas, restore from memCanvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.drawImage(this.memCanvas, 0, 0);

    let mouseX = e.nativeEvent.offsetX / canvas.width;
    let mouseY = e.nativeEvent.offsetY / canvas.height;

    // add a point
    let points = [...this.state.activeLinePoints];
    points.push({ x: mouseX, y: mouseY });
    this.setState({ activeLinePoints: points });

    // draw the current line
    this.drawPoints(points, ctx);
  }

  drawPoints(points, ctx) {
    if (points.length < 2) return;
    ctx.beginPath();
    ctx.moveTo(valFromPercent(points[0].x, ctx.canvas.width), valFromPercent(points[0].y, ctx.canvas.height));
    for (let i = 1; i < points.length - 2; i++) {
      const c = (valFromPercent(points[i].x, ctx.canvas.width) + valFromPercent(points[i + 1].x, ctx.canvas.width)) / 2;
      const d = (valFromPercent(points[i].y, ctx.canvas.height) + valFromPercent(points[i + 1].y, ctx.canvas.height)) / 2;
      ctx.quadraticCurveTo(valFromPercent(points[i].x, ctx.canvas.width), valFromPercent(points[i].y, ctx.canvas.height), c, d);
    }
    // final curve
    let i = points.length - 2;
    ctx.quadraticCurveTo(
      valFromPercent(points[i].x, ctx.canvas.width),
      valFromPercent(points[i].y, ctx.canvas.height),
      valFromPercent(points[i + 1].x, ctx.canvas.width),
      valFromPercent(points[i + 1].y, ctx.canvas.height)
    );
    ctx.strokeStyle = this.state.penColor;
    ctx.lineWidth = this.state.penSize;
    ctx.stroke();
  }

  drawAllSavedLines() {
    // if you want to re-draw lines from local state
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    let lines = this.state.canvasState["NO_SOCKET_MODE"] || [];
    lines.forEach((line) => {
      this.drawPoints(line.points, ctx);
    });
    let memctx = this.memCanvas.getContext("2d");
    memctx.clearRect(0, 0, this.memCanvas.width, this.memCanvas.height);
    memctx.drawImage(canvas, 0, 0);
  }

  clearAll() {
    // Clear everything
    let newCanvasState = { ...this.state.canvasState };
    newCanvasState["NO_SOCKET_MODE"] = [];
    this.setState({ canvasState: newCanvasState }, () => {
      this.drawAllSavedLines(); // re-draw
    });
  }

  undoLast() {
    let lines = [...(this.state.canvasState["NO_SOCKET_MODE"] || [])];
    lines.pop(); // remove last
    let newCanvasState = { ...this.state.canvasState };
    newCanvasState["NO_SOCKET_MODE"] = lines;
    this.setState({ canvasState: newCanvasState }, () => {
      this.drawAllSavedLines();
    });
  }

  togglePen() {
    this.setState({ penEnabled: !this.state.penEnabled });
  }

  setPenColor() {
    let color = prompt("Pen Color (hex):", this.state.penColor);
    if (color && /^#[0-9A-F]{6}$/i.test(color)) {
      this.setState({ penColor: color });
    }
  }

  setPenSize() {
    let size = prompt("Pen Size:", this.state.penSize);
    if (!isNaN(size)) {
      this.setState({ penSize: parseFloat(size) });
    }
  }

  render() {
    // The overlay has 2 layers:
    // 1) any "UI" for controlling pen
    // 2) the canvas for drawing lines
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 10, // ensure on top of #Inventum3D
          pointerEvents: "none", // The canvas can be pointerEvents: all if penEnabled
        }}>
        {/* The optional drawing UI */}
        {this.props.canUsePen && (
          <DrawingControls
            penEnabled={this.state.penEnabled}
            togglePen={this.togglePen}
            clearAll={this.clearAll}
            undoLast={this.undoLast}
            setPenColor={this.setPenColor}
            setPenSize={this.setPenSize}
          />
        )}

        {/* The actual pen canvas, pointerEvents = all only if penEnabled */}
        <canvas
          ref={this.canvasRef}
          style={{
            position: "absolute",
            pointerEvents: this.state.penEnabled ? "all" : "none",
            background: "none",
          }}
          onMouseDown={this.handleMouseDown}
          onMouseUp={this.handleMouseUp}
          onMouseMove={this.handleMouseMove}
        />
      </div>
    );
  }
}

function DrawingControls(props) {
  return (
    <div className="DrawingControls">
      <div className="DrawingControlsHeader">Drawing Controls</div>
      <div className="DrawingControlsButton" onClick={props.togglePen}>
        {props.penEnabled ? "Disable" : "Enable"} Pen
      </div>
      <div className="DrawingControlsButton" onClick={props.setPenColor}>
        Pen Color
      </div>
      <div className="DrawingControlsButton" onClick={props.setPenSize}>
        Pen Size
      </div>
      <div className="DrawingControlsButton" onClick={props.undoLast}>
        Undo
      </div>
      <div className="DrawingControlsButton" onClick={props.clearAll}>
        Clear All
      </div>
    </div>
  );
}

export default PenToolContainer;
