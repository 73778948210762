import * as THREE from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { store } from '../core/store.js';
import { resolutionManager } from '../core/resolution.js';
import { notifications } from '../ui/notifications.js';
import { ScreenLabel } from './screenLabel.js';
import { WorldLabel } from './worldLabel.js';
import { StaticTextLabel } from './StaticTextLabel.js';
import { StaticImageLabel } from './StaticImageLabel.js';

var controller = { public: {} };

let listeners = []; // Update Listeners.

let allLabels = [];
let worldLabelsList = []; // List
let worldLabelsUUID = {}; // UID
let labelsOID = {}; // Original ID
let controls = [];
let controlsMode = 'translate';
let helperObjects = [];// Dummy 3D objects for ScreenLabels to allow controls to have an object to attach to.
let debugLimits = [];
let activeLabel = {};
let lastGeneratedLabelID = null; // Cache for last ID value.

function findID () {
	// Find the largest ID number in use and return 1 greater.
	// Cache the result in lastGeneratedLabelID;
	// The cache will prevent ID reuse when deleting labels
	if (lastGeneratedLabelID === null || isNaN(lastGeneratedLabelID) || labelsOID[lastGeneratedLabelID + 1] !== undefined) {
		let idArray = Object.keys(labelsOID)
		.filter(key => !isNaN(parseInt(key)))
		.map(key => parseInt(key))
		.sort((a, b) => a - b);
		if (idArray.length == 0) {
			lastGeneratedLabelID = 1;
		}else {
			lastGeneratedLabelID = idArray[idArray.length - 1] + 1;
		}
	} else {
		console.log('Label from cache');
		lastGeneratedLabelID += 1;
	}
	console.log(lastGeneratedLabelID);
	return lastGeneratedLabelID;
}

controller.checkSelection = function (x, y, action) {
  // Checks for clicks on 3D labels.
  // Currently only used for labels with hyperlinks.

  if (action !== 'click') {
    return;
  }
  const tempModelList = [];
  const labelUUIDToURL = {};

  worldLabelsList.map((label, index) => {
    // For each 3D label, store the mesh and UUID of mesh.
    if (Object.prototype.hasOwnProperty.call(label, 'url')) {
      if (label.content.url.length > 1) {
        tempModelList.push(label.meshManager.getMesh());
        const backboardUUID = label.meshManager.getMesh().children[0].uuid;
        labelUUIDToURL[backboardUUID] = label.content.url;
      }
    }
  });

  var raycaster = new THREE.Raycaster();
  var mouse = new THREE.Vector2();

	const resolution = resolutionManager.getResolution();
  mouse.x = (x / resolution.width) * 2 - 1;
  mouse.y = -(y / resolution.height) * 2 + 1;
  raycaster.setFromCamera(mouse, store.camera);
  var intersects = raycaster.intersectObjects(tempModelList, true);
  if (intersects.length === 0) return false;
  const intersectUUID = intersects[0].object.uuid;
  if (!Object.prototype.hasOwnProperty.call(labelUUIDToURL, intersectUUID)) return false;
  // Open URL in new tab.
  window.open(labelUUIDToURL[intersectUUID], '_blank');
  store.controls.enabled = false;
  // Need to disable and renable the controls otherwise the click will get stuck due to the tab/window switch.
  window.setTimeout(() => {
    store.controls.enabled = true;
  }, 10);

  return true;
};

controller.createLabel = function (options) {
  if (Object.prototype.hasOwnProperty.call(labelsOID, options.id)) {
    console.warn('Unable to add label. Duplicate ID');
    return;
  }
  var tempLabel = {};
  if (options.type === '2D') {
    tempLabel = new ScreenLabel(options);
    labelsOID[options.id] = tempLabel;
    allLabels.push(tempLabel);
		tempLabel.marker.addClickCallback(() => {
			notifyLabelsUpdated();
		})
  } else if (options.type === '3D') {
    tempLabel = new WorldLabel(options);
    allLabels.push(tempLabel);
    labelsOID[options.id] = tempLabel;
    worldLabelsUUID[tempLabel.uuid] = tempLabel;
    worldLabelsList.push(tempLabel);
		tempLabel.marker.addClickCallback(() => {
			notifyLabelsUpdated();
		})
  } else if (options.type === 'STATIC_TEXT') {
    tempLabel = new StaticTextLabel(options);
    tempLabel.id = options.id;
    labelsOID[options.id] = tempLabel;
    allLabels.push(tempLabel);
  } else if (options.type === 'STATIC_IMAGE') {
    tempLabel = new StaticImageLabel(options);
    tempLabel.id = options.id;
    labelsOID[options.id] = tempLabel;
    allLabels.push(tempLabel);
  } else {
    console.warn('Invalid label type');
  }
};

controller.createTemporaryScreenLabel = function (options) {
  var tempLabel = new ScreenLabel(options);
  return tempLabel;
};

// Static Labels
controller.public.centerStaticLabel = (id) => {
  let label = labelsOID[id];
  if (!label) return;
  if (label.type !== 'STATIC_IMAGE' && label.type !== 'STATIC_TEXT') return;
  label.centerPosition();
}

controller.public.setStaticImage = (id, url, onComplete) => {
  let label = labelsOID[id];
  if (!label) return;
  if (label.type !== 'STATIC_IMAGE') return;
  fetch(url).then((response) => {
    if (response.status === 200) {
      label.loadImage(url);
      if (typeof(onComplete) === 'function') {
        console.log(label.imageURL);
        onComplete();
      }
    }else {
      console.log('Bad URL');
    }
  });
}

controller.public.setStaticPosition = (id, pos) => {
  // Position should be between 0 and 1
  let label = labelsOID[id];
  if (!label) return;
  if (label.type !== 'STATIC_TEXT' && label.type !== 'STATIC_IMAGE') return;
  if (!pos) return;
  label.setPosition(pos.x, pos.y);
}

controller.public.setStaticScale = (id, scaleFactor) => {
  let label = labelsOID[id];
  if (!label) return;
  if (label.type !== 'STATIC_TEXT' && label.type !== 'STATIC_IMAGE') return;
  if (isNaN(scaleFactor)) return;
  label.setSize(scaleFactor);
}

// 2D & 3D Labels

// 2D Only

controller.public.toggle2DSceneStateable = (id) => {
	let label = labelsOID[id];
	if (!label || label.type !== '2D') return;

	label.isSceneStateable = !label.isSceneStateable;
	notifyLabelsUpdated();
};

controller.public.toggleVisibleStart = (id) => {
	let label = labelsOID[id];
	if (!label) return;

	label.visibleStart = !label.visibleStart;
	notifyLabelsUpdated();
}

controller.public.createCaption = () => {
  const options = {
    type: 'STATIC_TEXT',
    id: findID()
  };
  controller.createLabel(options);
  controller.public.toggleVisible(options.id);
  controller.public.editText(options.id, 0);
  const label = labelsOID[options.id];
};

controller.public.debugLabel = (id) => {
  if (!labelsOID[id]) return;
  return labelsOID[id];
};

controller.public.create = (type) => {
  if (type !== '2D' && type !== '3D' && type !== 'STATIC_TEXT' && type !== 'STATIC_IMAGE') {
    return;
  }

  let options = {};

  if (type === '2D' || type === '3D') {
    options = {
      anchorPosition: { x: 1, y: 1, z: 1 },
      type: type,
      content: {
        text: [],
        style: {
          backgroundColor: '#212121',
          color: '#FFFFFF',
          fontAlign: 'center',
          padding: 0
        }
      },
      scale: 10,
      offsetX: 0,
      offsetY: 25,
      renderLimits: [],
      id: findID()
    };

		if (type === '2D') {
			options.isSceneStateable = true;
		}

    if (type === '3D') {
      options.offsetX = 3;
      options.offsetY = 3;
    }

    options.content.text.push('Label');
  }else if (type === 'STATIC_TEXT' || type === 'STATIC_IMAGE') {
    // STATIC_TEXT and STATIC_IMAGE have their defaults defined in the constructor
    options = {
      id: findID(),
      type: type
    }
  }

  controller.createLabel(options);
  store.setUnsavedScene();
  return options.id;
};

controller.public.setURL = (id) => {
  const label = labelsOID[id];
  if (!label) return;
  if (label.type !== '3D') return;
  let url = label.content.url || '';
  url = window.prompt('URL\nMust include http(s)://\nLeave blank to disable:', url);
  if (url === null) {
    return;
  }

  label.content.url = url;
  store.setUnsavedScene();
};

controller.public.duplicate = (id) => {
  const label = labelsOID[id];
  if (!label) {
    console.log('Bad label ID');
    return;
  }
  const serializedData = label.serialize();
  serializedData.id = findID();

  controller.createLabel(serializedData);
  store.requestRender();
  store.setUnsavedScene();
};

controller.public.setMarkerOffset = function (id, x, y) {
  const label = labelsOID[id];
  if (!label) {
    console.log('Bad label ID');
    return;
  }

  if (label.type !== '2D') {
    return;
  }

  if (isNaN(x) || isNaN(y)) {
    return;
  }

  label.setMarkerOffset(x, y);
  store.setUnsavedScene();
};

controller.public.toggleVisible = function (id) {
  const label = labelsOID[id];
  if (!label) {
    console.log('Bad label ID');
    return;
  }

  if (label.type === '3D') {
    //label.meshManager.pointer.visible = !label.meshManager.pointer.visible;
    //console.log(label);
    label.handleClick();
  } else if (label.type === '2D') {
		label.toggleVisible();
  }else if (label.type === 'STATIC_TEXT' || label.type === 'STATIC_IMAGE') {
    label.sprite.visible = !label.sprite.visible;
  }
  store.requestRender();
	notifyLabelsUpdated();
};

controller.public.scale = function (id, scale) {
  const label = labelsOID[id];
  if (!label) {
    console.log('Bad label ID');
    return;
  }
  if (label.type !== '3D') {
    return;
  }

  label.meshManager.setScale(scale);
  store.requestRender();
  store.setUnsavedScene();
};

controller.public.zoomTo = function (id) {
  const label = labelsOID[id];
  if (!label) {
    console.log('Bad label ID');
    return;
  }
  window.Inventum.camera.zoomToPoint(label.marker.getWorldPosition()); // FIXME Global Reference
};

controller.public.toggleAutoScale = function (id) {
  const label = labelsOID[id];
  if (!label) {
    console.log('Bad label ID');
    return;
  }

  if (label.type === '2D') {
    return; // Not needed for 2D labels as they always automatically scale
  }
  label.toggleAutoScale();
  store.requestRender();
  store.setUnsavedScene();
};

controller.public.toggleAutoRotate = function (id) {
  const label = labelsOID[id];
  if (!label) {
    console.log('Bad label ID');
    return;
  }

  if (label.type === '2D') {
    return; // Not needed for 2D labels as they always automatically rotate
  }
  label.toggleAutoRotate();
  store.requestRender();
  store.setUnsavedScene();
};

controller.hideAll = function hideAll () {
  allLabels.map(label => {
    if (label.type === '2D' && (label.isSceneStateable === undefined || !label.isSceneStateable)) return;
    label.setVisible(false);
  })

  if (store.broadcast) {
    store.sync.private.broadcast('LABELS_HIDE_ALL');
  }
	notifyLabelsUpdated();
};

controller.batchSetVisible = function setVisibility (labelArray, isVisible) {
  if (isVisible === undefined) {
    isVisible = true;
  }

  labelArray.map((labelID) => {
		if (labelID === undefined) return;
    const label = controller.getLabelByOriginalID(labelID);
    if (label === undefined || (label.type == '2D' && !label.isSceneStateable)) { return; }
    label.setVisible(isVisible, true);
  });

  if (store.broadcast) {
    store.sync.private.broadcast('LABELS_SET_VISIBLE', { labels: labelArray, isVisible: isVisible });
  }
	notifyLabelsUpdated();
};

controller.getLabelByOriginalID = function getLabelByOriginalID (id) {
  return labelsOID[id];
};

window.testLabels = () => {return labelsOID};

controller.getVisible = function getVisible () {
  const labelsVisible = [];
  allLabels.map(label => {
    if (label.type === '2D') {
			if (label.isSceneStateable === undefined || !label.isSceneStateable) return;
			if (!label.userHidden) {
				labelsVisible.push(label.id);
			}
		}else if (label.type === '3D') {
      if (label.meshManager.pointer.visible) {
        labelsVisible.push(label.id);
      }
    }else if (label.type === 'STATIC_TEXT' || label.type === 'STATIC_IMAGE') {
      if (label.sprite.visible) {
        labelsVisible.push(label.id);
      }
    }
  })
  return labelsVisible;
};

controller.render = function renderLabels () {
  worldLabelsList.map((label) => {
    label.handleRotate();
    label.handleScale();
  });
};

controller.reset = function reset () {
  allLabels = [];
  worldLabelsList = []; // List
  worldLabelsUUID = {}; // UID
  labelsOID = {}; // Original ID
  controls = [];
  controlsMode = 'translate';
  store.editingLabels = false;
  helperObjects = [];
	lastGeneratedLabelID = null;
};

function createControls (label) {
  const control = new TransformControls(store.camera, store.webglRenderer.domElement);
  control.addEventListener('dragging-changed', function (event) {
    store.controls.enabled = !event.value;
  });

  if (controls.length === 0) {
    document.addEventListener('keypress', handleControlsMode);
  }

  if (label.type === '3D') {
    label.toggleOffsetControls();
    const tMesh = label.meshManager.getMesh();
    control.addEventListener('change', (e) => {
      label.marker.setWorldPosition(tMesh.position);
      label.updateOffsetControls();
      store.requestRender();
    });
    control.attach(tMesh);
  } else if (label.type === '2D') {
    // ScreenLabels needs an Object3D to move around and map to. Then we get the position of that object 3D
    // And pass it to marker.setWorldPosition.
    // As ScreenLabels don't have an Object3D parent this won't work.
    // We should create a sphere or something as a dummy Object3D and then set it's position to the markers world position.
    // Then we can move around and update it

    var tGeom = new THREE.BoxGeometry(1, 1, 1);
    var tMat = new THREE.MeshBasicMaterial({ color: 0xFF0000 });
    var tCube = new THREE.Mesh(tGeom, tMat);
    tCube.visible = true;
    tCube.position.copy(label.marker.getWorldPosition());
    store.scene.add(tCube);
    control.attach(tCube);

    control.addEventListener('change', (e) => {
      label.marker.setWorldPosition(tCube.position);
      store.requestRender();
    });
    helperObjects.push(tCube);
  }
  store.scene.add(control);
  controls.push(control);
  store.requestRender();
}

function destroyControls () {
  controls.map((control) => {
    control.detach();
    store.scene.remove(control);
  });
  helperObjects.map((obj) => {
    store.scene.remove(obj);
  });
  controls = [];
  store.requestRender();
  // document.removeEventListener('keydown',handleControlsMode);
}

function handleControlsMode (e) {
  switch (e.keyCode) {
    case 82:
    case 114:
      controlsMode = 'rotate';
      break;
    case 87:
    case 119:
      controlsMode = 'translate';
      break;
  }
  controls.map((control) => {
    control.setMode(controlsMode);
    if (controlsMode === 'rotate') {
      // control.showZ = false;
      control.setRotationSnap(THREE.Math.degToRad(5));
    } else {
      // control.showZ = true;
    }
  });
  store.requestRender();
}

controller.public.getLabelIDS = () => {
  return Object.keys(labelsOID);
};

controller.public.locationControl = (id) => {
  const label = labelsOID[id];
  if (!label) {
    console.log('Bad label ID');
    console.log(id);
    console.log(labelsOID);
    return;
  }

  if (label === activeLabel) {
    destroyControls();
    if (activeLabel.type === '3D') {
      activeLabel.toggleOffsetControls();
    }
    activeLabel = {};
    store.editingLabels = false;
    return;
  }
  store.editingLabels = true;
  destroyControls();

  // Removes the offset controls from the previous active label if there is one
  if (Object.keys(activeLabel).length > 0) {
    if (activeLabel.type === '3D') {
      activeLabel.toggleOffsetControls();
    }
  }

  activeLabel = label;
  createControls(label);
  store.setUnsavedScene();
};

controller.public.addLimit = (id, limit) => {
  const label = labelsOID[id];
  if (!label) {
    return;
  }
  const newLimit = {};

  if (limit === 'ABOVE_ALTITUDE' || limit === 'BELOW_ALTITUDE') {
    if (label.renderLimits.length > 0) {
      let canAdd = true;
      label.renderLimits.map(existingLimit => {
        if (existingLimit.type === 'ABOVE_ALTITUDE' || existingLimit.type === 'BELOW_ALTITUDE' || existingLimit.type === 'DISTANCE' || existingLimit.type === 'ALTITUDE') {
          canAdd = false;
        }
      });
      if (!canAdd) {
        notifications.add({ content: 'Only one ALTITUDE or DISTANCE limit can exist' });
        return;
      }
    }
    newLimit.value = 40; // Default value for Altitude
  } else if (limit === 'DISTANCE') {
    if (label.renderLimits.length > 0) {
      let canAdd = true;
      label.renderLimits.map(existingLimit => {
        if (existingLimit.type === 'ABOVE_ALTITUDE' || existingLimit.type === 'BELOW_ALTITUDE' || existingLimit.type === 'DISTANCE' || existingLimit.type === 'ALTITUDE') {
          canAdd = false;
        }
      });
      if (!canAdd) {
        notifications.add({ content: 'Only one ALTITUDE or DISTANCE limit can exist' });
        return;
      }
    }
    newLimit.value = 40;
  } else if (limit === 'EXCLUSION' || limit === 'INCLUSION') {
    if (label.renderLimits.length > 0) {
      let canAdd = true;
      label.renderLimits.map(existingLimit => {
        if ((existingLimit.type === 'EXCLUSION' && limit === 'INCLUSION') || (existingLimit.type === 'INCLUSION' && limit === 'EXCLUSION')) {
          canAdd = false;
        }
      });
      if (!canAdd) {
        notifications.add({ content: "Can't mix Inclusion and Exclusion limits" });
        return;
      }
    }
    newLimit.value = window.Inventum.camera.getCameraPositionWorld(); // Default position value for Exclusion or Inclusion
    newLimit.value.d = 5;
  }

  newLimit.type = limit; // Set the type to

  label.addRenderLimit(newLimit);
  store.requestRender();
  store.setUnsavedScene();
};

controller.public.showLimitBounds = (params) => {
  const tSphereGeom = new THREE.SphereGeometry(params.d, 32, 32);
  const tMat = new THREE.MeshLambertMaterial({ color: 0xFF0000, opacity: 0.5, transparent: true });
  const tSphere = new THREE.Mesh(tSphereGeom, tMat);
  tSphere.position.set(params.x, params.y, params.z);
  store.scene.add(tSphere);
  debugLimits.push(tSphere);
  store.requestRender();
};

controller.public.clearLimitBounds = () => {
  debugLimits.map(tSphere => {
    store.scene.remove(tSphere);
  });
  debugLimits = [];
  store.requestRender();
};

controller.public.updateRenderLimit = (labelID, limitID, value) => {
  const label = labelsOID[labelID];
  if (!label) {
    return;
  }

  if (!label.renderLimits[limitID]) {
    return;
  }

  label.updateRenderLimit(limitID, value);
  notifications.add({ content: 'Limit Updated!' });
  store.requestRender();
  store.setUnsavedScene();
};

controller.public.getRenderLimits = (id) => {
  const label = labelsOID[id];
  if (!label) {
    return [];
  }
  var tLimits = [];
  label.renderLimits.map(limit => {
    tLimits.push(Object.assign({}, limit));
  });
  return tLimits;
};

controller.public.deleteRenderLimit = (labelID, limitID) => {
  const label = labelsOID[labelID];
  if (!label) {
    return;
  }
  label.deleteRenderLimit(limitID);
};

controller.public.delete = (id) => {
  const label = labelsOID[id];
  if (!label) {
    return;
  }
  label.remove();
  delete labelsOID[id];
  const updatedLabels = [];

  allLabels.map((label) => {
    if (label.id !== id) {
      updatedLabels.push(label);
    }
  });
  allLabels = updatedLabels;
	notifyLabelsUpdated();
  store.requestRender();
  store.setUnsavedScene();
};

controller.public.getLabels = () => {
  const cleanedData = [];
  allLabels.map((label) => {
    let badLabel = false;

    const labelData = {
      id: label.id,
      type: label.type
    };

    // Render Limits
    if (label.type === '2D' || label.type === '3D') {
      labelData.text = label.content.text.slice();
      labelData.style = Object.assign({}, label.content.style);
      labelData.marker = {};
			labelData.visibleStart = label.visibleStart;
			labelData.transformActive = (label === activeLabel ? true : false);
      const renderLimits = [];
      if (Object.prototype.hasOwnProperty.call(label, 'renderLimits')) {
        label.renderLimits.map((limit) => {
          renderLimits.push(Object.assign({}, limit));
        });
      }
      labelData.renderLimits = renderLimits;
      if (label.type === '3D') {
        labelData.scale = label.meshManager.getScale();
        labelData.autoRotate = label.settings.autoRotate;
        labelData.autoScale = label.settings.autoScale;
        labelData.marker.hoverSize = label.settings.markerHoverSize;
        labelData.marker.hoverBackground = label.settings.markerHoverBackground;
        labelData.marker.inactiveSize = label.settings.markerInactiveSize;
        labelData.marker.inactiveBackground = label.settings.markerInactiveBackground;
        labelData.marker.showMarker = label.settings.showMarker;
        labelData.visible = !label.userHidden;
      } else if (label.type === '2D') {
        labelData.marker.hoverSize = label.markerHoverSize;
        labelData.marker.hoverBackground = label.markerHoverBackground;
        labelData.marker.inactiveSize = label.markerInactiveSize;
        labelData.marker.inactiveBackground = label.markerInactiveBackground;
        labelData.marker.showMarker = label.showMarker;
        labelData.marker.offsetX = label.offsetX;
        labelData.marker.offsetY = label.offsetY;
				labelData.isSceneStateable = label.isSceneStateable;
				labelData.visible = !label.userHidden;
      }

    } else if (label.type === 'STATIC_IMAGE' || label.type === 'STATIC_TEXT') {
      labelData.scale = label.scaleFactor;
      labelData.positionX = label.screenPosition.x;
      labelData.positionY = label.screenPosition.y;
      labelData.visible = label.sprite.visible;
			labelData.visibleStart = label.visibleStart;
      if (label.type === 'STATIC_TEXT') {
        labelData.text = label.getText();
        labelData.style = label.getStyle();
      }else {
        // Static Image
        labelData.imageURL = label.imageURL;
      }
    } else {
      console.log('Unsupported Label Type');
      badLabel = true;
    }

    if (!badLabel) {
      cleanedData.push(labelData);
    }
  });
  return cleanedData;
};

controller.public.setLabelStyle = (id, style) => {
  const label = labelsOID[id];
  if (!label) { return; }

  if (style.padding) {
    style.padding = parseInt(style.padding);
  }
  label.setStyle(style);
  store.requestRender();

  store.setUnsavedScene();
};

controller.public.setMarkerStyle = (id, style) => {
  const label = labelsOID[id];
  if (!label) { return; }
  label.setMarkerStyle(style);
  if (label.type === '2D') {
    label.setMarkerOffset(style.offsetX, style.offsetY);
  }
  store.setUnsavedScene();
};

controller.public.addText = (id, text) => {
  const label = labelsOID[id];
  if (!label) {
    return;
  }

	if (text === undefined) {
		text = window.prompt('Text Line:', 'Your Text');
		if (text === null || text === '') {
			return;
		}
	}

  label.addText(text);
  store.requestRender();
  store.setUnsavedScene();
};

controller.public.editText = (id, index, textValue) => {
  const label = labelsOID[id];
  if (!label) {
    return;
  }
  if (label.type !== '2D' && label.type !== '3D' && label.type !== 'STATIC_TEXT') {
    return;
  }
	if (textValue === undefined) {
		let oldText = '';

		if (label.type === '2D' || label.type === '3D') {
			oldText = label.content.text[index];
		}else if (label.type === 'STATIC_TEXT') {
			oldText = label.canvasTexture.textArray[index];
		}

		textValue = window.prompt('Text Line:', oldText);

		if (textValue === null || textValue === '') {
			return;
		}
	}
  label.editText(index, textValue);
  store.requestRender();
  store.setUnsavedScene();
};

controller.public.clearTextLine = (id, index) => {
  let textArr = null;
  const label = labelsOID[id];
  if (!label) {
    return;
  }

  if (label.type !== '2D' && label.type !== '3D' && label.type !== 'STATIC_TEXT') {
    return;
  }

  if (label.type === '2D' || label.type === '3D') {
    textArr = label.content.text;
  } else if (label.type === 'STATIC_TEXT') {
    textArr = label.canvasTexture.textArray;
  }

  if (textArr.length === 1) {
    label.editText(0, 'Untitled Label'); // If removing only text item rename it to untitled label
  } else {
    textArr.splice(index, 1);
    label.editText(0, textArr[0]); // Force label to update
  }

  store.requestRender();
  store.setUnsavedScene();
};

controller.public.clearText = (id) => {
  const label = labelsOID[id];
  if (!label) {
    return;
  }
  const emptyArray = [];
  label.content.text = emptyArray;
  label.backboard.replaceText(emptyArray);
  store.requestRender();
  store.setUnsavedScene();
};

controller.modifyScale = (scale) => {
  allLabels.map((label) => {
    if (label.type === 'STATIC_TEXT' || label.type === 'STATIC_IMAGE' || !label.marker) {
      return;
    }

    label.marker.modifyScale(scale);
    // if label is 3D then modify the 3D positon too
    if (label.type === '3D') {
      label.modifyScale(scale);
    }
  });
  store.setUnsavedScene();
};

controller.modifyTransform = (deltaTransform) => {
  allLabels.map((label) => {
    if (label.type === 'STATIC_TEXT' || label.type === 'STATIC_IMAGE' || !label.marker) {
      return;
    }

    label.marker.modifyTransform(deltaTransform);
    // if label is 3D then modify the 3D positon too
    if (label.type === '3D') {
      label.modifyTransform(deltaTransform);
    }
  });
  store.setUnsavedScene();
};

function notifyLabelsUpdated () {
	for (const key in listeners) {
		const cb = listeners[key];
		if (typeof(cb) === 'function') {
			cb();
		}
	}
}

controller.public.addUpdateListener = (cb) => {
	if (typeof(cb) !== 'function') return;
	let key = 0;
	while (listeners[key] !== undefined) {
		key++;
	}
	listeners[key] = cb;
	return key;
}

controller.public.removeUpdateListener = (key) => {
	if (listeners[key]) {
		delete listeners[key];
	}
}

controller.setVisibleStartFromCurrentState = function setVisibleStartFromCurrentState () {
	allLabels.map(label => {
		if (label.isVisible()) {
			label.visibleStart = true;
		}else {
			label.visibleStart = false;
		}
	});
};

controller.showVisibleStart = function showVisibleStart () {
	allLabels.map(label => {
		if (label.visibleStart) {
			label.setVisible(true);
		}else {
			label.setVisible(false);
		}
	});
};

controller.public.getLabelName = function (id) {
  const label = labelsOID[id];
  return label ? label.name : null;
};

controller.public.updateLabelName = function (id, newName) {
  const label = labelsOID[id];
  if (label) {
    label.name = newName;
    notifyLabelsUpdated(); 
  } else {
    console.warn(`Label with ID ${id} not found.`);
  }
};

controller.generateJSON = () => {
  const serializedLabels = [];
  allLabels.map((label) => {
    serializedLabels.push(label.serialize());
  });
  return ({ labels: serializedLabels });
};

controller.public.setPointerVisible = function(id, isVisible) {
  const label = labelsOID[id];
  if (!label) {
    console.log('Bad label ID');
    return;
  }
  
  label.setPointerVisible(isVisible);
  store.requestRender();
};

export { controller as labels };
