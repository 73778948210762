import React, { useState, useEffect } from "react";
import { ChromePicker } from "react-color";

function StylePanel(props) {
  const [style, setStyle] = useState(props.style);
  const [activeInput, setActiveInput] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (props.style !== style) {
      setStyle(props.style);
    }
  }, [props.style]);

  const handleChange = (e) => {
    const newStyle = { ...style };
    newStyle[activeInput] = e.target.value;
    setStyle(newStyle);
  };

  const handleNumericChange = (key, value) => {
    if (isNaN(value)) return;
    const newStyle = { ...style, [key]: Number(value) };
    setStyle(newStyle);
    const inventumStyle = JSON.parse(JSON.stringify(newStyle));
    Inventum.labels.setLabelStyle(props.id, inventumStyle);
    props.getLabels();
  };

  const handleColorChange = (value) => {
    const newStyle = { ...style, [activeInput]: value };
    setStyle(newStyle);
    const inventumStyle = JSON.parse(JSON.stringify(newStyle));
    Inventum.labels.setLabelStyle(props.id, inventumStyle);
    props.getLabels();
  };

  const updateStyle = (e) => {
    e.stopPropagation();
    const clonedStyle = JSON.parse(JSON.stringify(style));
    Inventum.labels.setLabelStyle(props.id, clonedStyle);
    setActiveInput(null);
    props.getLabels();
  };

  return (
    <div>
      <div
        className="EditorDrawerItemHeader"
        onClick={() => setOpen(!open)}
      >
        <span className="PanelOpenStatus">{open ? "▾" : "▸"}</span>
        <span>Style</span>
      </div>
      {open && (
        <StyleDrawer
          exclude={props.exclude}
          style={style}
          activeInput={activeInput}
          clearActiveInput={() => setActiveInput(null)}
          handleChange={handleChange}
          handleColorChange={handleColorChange}
          handleNumericChange={handleNumericChange}
          handleToggle={(key) => {
            // Only set activeInput if none is active
            if (!activeInput) {
              setActiveInput(key);
            }
          }}
          updateStyle={updateStyle}
        />
      )}
    </div>
  );
}

function StyleDrawer(props) {
  const excludeList = props.exclude || [];
  const fieldInputTypes = {
    color: ["color", "backgroundColor"],
    numeric: ["padding", "lineSpacing", "paddingHeight", "borderRadius"],
    select: ["fontAlign"],
    text: ["font"],
  };

  return (
    <div>
      {Object.keys(props.style)
        .filter((key) => !excludeList.includes(key))
        .map((item, index) => {
          let inputType = "text";
          for (let fieldType in fieldInputTypes) {
            if (fieldInputTypes[fieldType].includes(item)) {
              inputType = fieldType;
              break;
            }
          }

          if (inputType === "color") {
            return <ColorInput key={index} item={item} index={index} {...props} />;
          } else if (inputType === "numeric") {
            return <NumericInput key={index} item={item} index={index} {...props} />;
          } else if (inputType === "select") {
            return <SelectInput key={index} item={item} index={index} {...props} />;
          } else {
            return <TextInput key={index} item={item} index={index} {...props} />;
          }
        })}
    </div>
  );
}

const LABEL_MAP = {
  color: "Colour",
  backgroundColor: "Background Colour",
  padding: "Padding",
  lineSpacing: "Line Spacing",
  paddingHeight: "Padding Height",
  borderRadius: "Border Radius",
  fontAlign: "Text Align",
  font: "Font",
};

function TextInput(props) {
  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell" title={props.item}>
        {LABEL_MAP[props.item] ?? props.item}
      </div>
      <div className="PanelValueCell" title={props.style[props.item]} onClick={() => props.handleToggle(props.item)}>
        {props.activeInput === props.item ? (
          <div>
            <input style={{ width: "100px" }} onChange={props.handleChange} value={props.style[props.item]} />{" "}
            <span className="PanelValueCellUpdateBtn" onClick={props.updateStyle}>
              Update
            </span>{" "}
          </div>
        ) : (
          <span>{props.style[props.item]}</span>
        )}
      </div>
    </div>
  );
}

function NumericInput(props) {
  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell" title={props.item}>
        {LABEL_MAP[props.item] ?? props.item}
      </div>
      <div className="PanelValueCell" title={props.style[props.item]} onClick={() => props.handleToggle(props.item)}>
        <div>
          <input
            type="number"
            style={{ width: "100px" }}
            onChange={(e) => {
              props.handleNumericChange(props.item, e.target.value);
            }}
            value={props.style[props.item]}
          />
        </div>
      </div>
    </div>
  );
}

function ColorInput(props) {
  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell" title={props.item}>
        {LABEL_MAP[props.item] ?? props.item}
      </div>
      <div
        className="ColorValueCell"
        style={{ background: props.style[props.item] }}
        onClick={() => {
          if (props.activeInput === props.item) {
            return;
          }
          props.handleToggle(props.item);
        }}>
        {props.activeInput === props.item ? (
          <ColorSelector
            handleClose={props.clearActiveInput}
            item={props.item}
            color={props.style[props.item]}
            handleChange={props.handleColorChange}
          />
        ) : null}
      </div>
    </div>
  );
}

function ColorSelector(props) {
  return (
    <div>
      <ChromePicker
        onChange={(e) => {
          props.handleChange(`rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`);
        }}
        color={props.color}
      />
      <div className="ColorCloseButton" onClick={props.handleClose}>
        Close
      </div>
    </div>
  );
}

function SelectInput(props) {
  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell" title={props.item}>
        {LABEL_MAP[props.item] ?? props.item}
      </div>
      <div className="PanelValueCell" title={props.style[props.item]} onClick={() => props.handleToggle(props.item)}>
        {props.activeInput === props.item ? (
          <div>
            {/* Render a <select> with your alignment options */}
            <select
              style={{ width: "100px" }}
              value={props.style[props.item]}
              onChange={(e) => {
                // We reuse your handleChange, passing the new value
                props.handleChange({ target: { value: e.target.value } });
              }}>
              <option value="left">left</option>
              <option value="center">center</option>
              <option value="right">right</option>
            </select>{" "}
            <span className="PanelValueCellUpdateBtn" onClick={props.updateStyle}>
              Update
            </span>{" "}
          </div>
        ) : (
          // Show the text (left, center, or right) when not active
          <span>{props.style[props.item]}</span>
        )}
      </div>
    </div>
  );
}


export { StylePanel };
