import React, { useState, useEffect } from "react";

function WorldMeshPanel(props) {
  const [activeInput, setActiveInput] = useState(null);
  const [tempScale, setTempScale] = useState(props.scale);
  const [visible, setVisible] = useState(props.visible);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setTempScale(props.scale);
    setVisible(props.visible);
  }, [props.scale, props.visible]);

  const updateScale = () => {
    if (isNaN(Number(tempScale))) {
      // revert if invalid
      setTempScale(props.scale);
      return;
    }
    Inventum.labels.scale(props.id, Number(tempScale));
    props.getLabels();
  };

  const updateChecked = (option) => {
    if (option === "autoRotate") {
      Inventum.labels.toggleAutoRotate(props.id);
    } else if (option === "autoScale") {
      Inventum.labels.toggleAutoScale(props.id);
    }
    props.getLabels();
  };

  const toggleLabelPointerVisibility = () => {
    const newVisibility = !visible;
    if (newVisibility && !props.labelVisible) {
      Inventum.labels.toggleVisible(props.id);
    }
    Inventum.labels.setPointerVisible(props.id, newVisibility);
    props.onPointerVisibilityChange(newVisibility);
    setVisible(newVisibility);
  };

  return (
    <div>
      <div
        className="EditorDrawerItemHeader"
        onClick={() => setOpen(!open)}
      >
        <span className="PanelOpenStatus">{open ? "▾" : "▸"}</span>
        <span>Mesh</span>
      </div>
      {open && (
        <StyleDrawer
          {...props}
          visible={visible}
          toggleVisibility={toggleLabelPointerVisibility}
          tempScale={tempScale}
          updateScale={updateScale}
          updateChecked={updateChecked}
          activeInput={activeInput}
          handleScaleChange={(e) => setTempScale(e.target.value)}
          handleToggle={(key) => {
            if (!activeInput) {
              setActiveInput(key);
            }
          }}
          setActiveInput={setActiveInput}
          clearActiveInput={() => setActiveInput(null)}
        />
      )}
    </div>
  );
}

function StyleDrawer(props) {
  return (
    <div>
      <ToggleLabelPointerVisibility
        visible={props.visible}
        toggleVisibility={props.toggleVisibility}
      />
      <SetScale {...props} />
      <ToggleAutoScale {...props} />
      <ToggleAutoRotate {...props} />
    </div>
  );
}

function SetScale(props) {
  if (!Object.prototype.hasOwnProperty.call(props, "scale")) {
    return null;
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      props.updateScale();
      props.clearActiveInput();
    }
  };

  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell" title="Label Scale">
        Label Scale
      </div>
      <div
        className="PanelValueCell"
        title="Label Scale"
        onClick={() => {
          props.setActiveInput("scale");
        }}
      >
        {props.activeInput === "scale" ? (
          <div>
            <input
              style={{ width: "100px" }}
              onChange={props.handleScaleChange}
              value={props.tempScale}
              onKeyDown={handleKeyDown}
              onBlur={() => {
                props.updateScale();
                props.clearActiveInput();
              }}
            />
          </div>
        ) : (
          <span>{props.tempScale}</span>
        )}
      </div>
    </div>
  );
}

function ToggleLabelPointerVisibility(props) {
  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell" title="Label Pointer Visibility">
        Label Pointer Visibility
      </div>
      <div
        className="PanelValueCell"
        style={{ padding: "5px", minWidth: "unset" }}
      >
        <input
          type="checkbox"
          checked={props.visible}
          onChange={props.toggleVisibility}
        />
      </div>
    </div>
  );
}

function ToggleAutoRotate(props) {
  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell" title="Auto-Rotate">
        Auto-Rotate
      </div>
      <div
        className="PanelValueCell"
        title="Auto-Rotate"
        style={{ padding: "5px", minWidth: "unset" }}
      >
        <input
          type="checkbox"
          checked={props.autoRotate}
          onChange={() => props.updateChecked("autoRotate")}
        />
      </div>
    </div>
  );
}

function ToggleAutoScale(props) {
  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell" title="Auto-Scale">
        Auto-Scale
      </div>
      <div
        className="PanelValueCell"
        title="Auto-Scale"
        style={{ padding: "5px", minWidth: "unset" }}
      >
        <input
          type="checkbox"
          checked={props.autoScale}
          onChange={() => props.updateChecked("autoScale")}
        />
      </div>
    </div>
  );
}

export { WorldMeshPanel };
