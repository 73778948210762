import React, { useState, useEffect } from "react";
import { ChromePicker } from "react-color";

function ColorSelector(props) {
  return (
    <div>
      <ChromePicker
        onChange={(e) => {
          props.updateMarker(
            props.markerKey,
            `rgba(${e.rgb.r},${e.rgb.g},${e.rgb.b},${e.rgb.a})`
          );
        }}
        color={props.color}
      />
      <div className="ColorCloseButton" onClick={props.handleClose}>
        Close
      </div>
    </div>
  );
}

function ToggleMarkerControl(props) {
  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell" title="Marker Visible (Requires Reload)">
        Marker Visible*
      </div>
      <div
        className="PanelValueCell"
        title="Marker Visible (Requires Reload)"
        style={{ padding: "5px", minWidth: "unset" }}
      >
        <input
          type="checkbox"
          checked={props.marker.showMarker}
          onChange={(e) => props.updateMarker("showMarker", e.target.checked)}
        />
      </div>
    </div>
  );
}

function SetOffsetXControl(props) {
  if (!Object.prototype.hasOwnProperty.call(props.marker, "offsetX")) {
    return null;
  }

  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell" title={"Marker Offset X"}>
        Marker Offset X
      </div>
      <div
        className="PanelValueCell"
        title={"Marker Offset X"}
        onClick={() => {
          props.setActiveInput("offsetX");
        }}
      >
        {props.activeInput === "offsetX" ? (
          <div>
            <input
              style={{ width: "100px" }}
              onChange={props.handleChange}
              value={props.marker.offsetX}
            />{" "}
            <span
              className="PanelValueCellUpdateBtn"
              onClick={(e) => {
                e.stopPropagation();
                props.updateMarker();
                props.clearActiveInput();
              }}
            >
              Update
            </span>{" "}
          </div>
        ) : (
          <span>{props.marker.offsetX}</span>
        )}
      </div>
    </div>
  );
}

function SetOffsetYControl(props) {
  if (!Object.prototype.hasOwnProperty.call(props.marker, "offsetY")) {
    return null;
  }

  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell" title={"Marker Offset Y"}>
        Marker Offset Y
      </div>
      <div
        className="PanelValueCell"
        title={"Marker Offset Y"}
        onClick={() => {
          props.setActiveInput("offsetY");
        }}
      >
        {props.activeInput === "offsetY" ? (
          <div>
            <input
              style={{ width: "100px" }}
              onChange={props.handleChange}
              value={props.marker.offsetY}
            />{" "}
            <span
              className="PanelValueCellUpdateBtn"
              onClick={(e) => {
                e.stopPropagation();
                props.updateMarker();
                props.clearActiveInput();
              }}
            >
              Update
            </span>{" "}
          </div>
        ) : (
          <span>{props.marker.offsetY}</span>
        )}
      </div>
    </div>
  );
}

function SetInactiveBackgroundControl(props) {
  if (!Object.prototype.hasOwnProperty.call(props.marker, "inactiveBackground")) {
    return null;
  }

  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell">Inactive Color</div>
      <div
        className="ColorValueCell"
        style={{ background: props.marker.inactiveBackground }}
        onClick={() => {
          if (props.activeInput === "inactiveBackground") return;
          props.setActiveInput("inactiveBackground");
        }}
      >
        {props.activeInput === "inactiveBackground" ? (
          <ColorSelector
            handleClose={props.clearActiveInput}
            markerKey="inactiveBackground"
            color={props.marker.inactiveBackground}
            updateMarker={props.updateMarker}
            getLabels={props.getLabels}
          />
        ) : null}
      </div>
    </div>
  );
}

function SetHoverBackgroundControl(props) {
  if (!Object.prototype.hasOwnProperty.call(props.marker, "hoverBackground")) {
    return null;
  }

  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell">Hover Color</div>
      <div
        className="ColorValueCell"
        style={{ background: props.marker.hoverBackground }}
        onClick={() => {
          if (props.activeInput === "hoverBackground") return;
          props.setActiveInput("hoverBackground");
        }}
      >
        {props.activeInput === "hoverBackground" ? (
          <ColorSelector
            handleClose={props.clearActiveInput}
            markerKey="hoverBackground"
            color={props.marker.hoverBackground}
            updateMarker={props.updateMarker}
            getLabels={props.getLabels}
          />
        ) : null}
      </div>
    </div>
  );
}

function SetInactiveSizeControl(props) {
  if (!Object.prototype.hasOwnProperty.call(props.marker, "inactiveSize")) {
    return null;
  }

  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell" title={"Inactive Size"}>
        Inactive Size
      </div>
      <div
        className="PanelValueCell"
        title={"Inactive Size"}
        onClick={() => {
          props.setActiveInput("inactiveSize");
        }}
      >
        {props.activeInput === "inactiveSize" ? (
          <div>
            <input
              style={{ width: "100px" }}
              onChange={props.handleChange}
              value={props.marker.inactiveSize}
            />{" "}
            <span
              className="PanelValueCellUpdateBtn"
              onClick={(e) => {
                e.stopPropagation();
                props.updateMarker();
                props.clearActiveInput();
              }}
            >
              Update
            </span>{" "}
          </div>
        ) : (
          <span>{props.marker.inactiveSize}</span>
        )}
      </div>
    </div>
  );
}

function SetActiveSizeControl(props) {
  if (!Object.prototype.hasOwnProperty.call(props.marker, "hoverSize")) {
    return null;
  }

  return (
    <div style={{ display: "flex", fontSize: "14px", marginBottom: "10px" }}>
      <div className="PanelKeyCell" title="Active Size">
        Hover Size
      </div>
      <div
        className="PanelValueCell"
        title={"Hover Size"}
        onClick={() => {
          props.setActiveInput("hoverSize");
        }}
      >
        {props.activeInput === "hoverSize" ? (
          <div>
            <input
              style={{ width: "100px" }}
              onChange={props.handleChange}
              value={props.marker.hoverSize}
            />{" "}
            <span
              className="PanelValueCellUpdateBtn"
              onClick={(e) => {
                e.stopPropagation();
                props.updateMarker();
                props.clearActiveInput();
              }}
            >
              Update
            </span>{" "}
          </div>
        ) : (
          <span>{props.marker.hoverSize}</span>
        )}
      </div>
    </div>
  );
}

function MarkerDrawer(props) {
  return (
    <div>
      <ToggleMarkerControl {...props} />
      <SetOffsetXControl {...props} />
      <SetOffsetYControl {...props} />
      <SetInactiveSizeControl {...props} />
      <SetActiveSizeControl {...props} />
      <SetInactiveBackgroundControl {...props} />
      <SetHoverBackgroundControl {...props} />
    </div>
  );
}

function MarkerPanel(props) {
  const [open, setOpen] = useState(false);
  const [activeInput, setActiveInput] = useState(null);
  const [marker, setMarker] = useState(props.marker);

  useEffect(() => {
    setMarker(props.marker);
  }, [props.marker]);

  const updateMarker = (key, value) => {
    // clone the current marker
    const newMarker = JSON.parse(JSON.stringify(marker));

    if (key !== undefined && value !== undefined) {
      newMarker[key] = value;
    }

    Inventum.labels.setMarkerStyle(props.id, newMarker);
    props.getLabels();
  };

  const handleChange = (e) => {
    const newMarker = { ...marker };
    newMarker[activeInput] = e.target.value;
    setMarker(newMarker);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const clearActiveInput = () => {
    setActiveInput(null);
  };

  return (
    <div>
      <div className="EditorDrawerItemHeader" onClick={toggleOpen}>
        <span className="PanelOpenStatus">{open ? "▾" : "▸"}</span>
        <span>Marker</span>
      </div>
      {open && (
        <MarkerDrawer
          clearActiveInput={clearActiveInput}
          setActiveInput={setActiveInput}
          activeInput={activeInput}
          getLabels={props.getLabels}
          handleChange={handleChange}
          updateMarker={updateMarker}
          marker={marker}
        />
      )}
    </div>
  );
}

export { MarkerPanel };
