import React, { useState, useEffect } from "react";

function LimitsEditor(props) {
  const [selectedOption, setSelectedOption] = useState("INCLUSION");

  useEffect(() => {
    return () => {
      Inventum.labels.clearLimitBounds();
    };
  }, []);

  const updateSelected = (option) => {
    setSelectedOption(option);
  };

  const addLimit = () => {
    Inventum.labels.addLimit(props.data.id, selectedOption);
    props.data.getLabels();
  };

  const handleDelete = (limitID) => {
    Inventum.labels.deleteRenderLimit(props.data.id, limitID);
    props.data.getLabels();
  };

  const handleValue = (limitID) => {
    const labelID = props.data.id;
    const limit = props.data.renderLimits[limitID];

    if (limit.type === "EXCLUSION" || limit.type === "INCLUSION") {
      // For inclusion and exclusion, we don't provide an optional value.
      // Instead we ask the camera manager for the current position
      const position = Inventum.camera.getCameraPositionWorld(); 
      // Merge with the existing { x, y, z, d } object
      const updatedValue = Object.assign({}, limit.value, position);
      Inventum.labels.updateRenderLimit(labelID, limitID, updatedValue);
    } else {
      // For everything else, prompt the user for a numeric value
      let providedValue = prompt("Value:", limit.value);

      if (providedValue == null || providedValue === "") return;
      providedValue = Number(providedValue);
      if (isNaN(providedValue)) {
        return;
      }
      Inventum.labels.updateRenderLimit(labelID, limitID, providedValue);
    }
    props.data.getLabels();
  };

  const handleOther = (limitID) => {
    const labelID = props.data.id;
    const limit = props.data.renderLimits[limitID];
    if (limit.type !== "EXCLUSION" && limit.type !== "INCLUSION") {
      return;
    }

    const currentValue = limit.value;
    let newDistance = prompt("Distance:", currentValue.d);
    if (newDistance == null || newDistance === "") return;
    newDistance = Number(newDistance);
    if (isNaN(newDistance)) {
      return;
    }

    currentValue.d = newDistance;
    Inventum.labels.updateRenderLimit(labelID, limitID, currentValue);
    props.data.getLabels();
  };

  // Split the array into inc/exc vs other
  const incExcLimits = [];
  const otherLimits = [];
  props.data.renderLimits.forEach((limit, index) => {
    limit.limitID = index;
    if (limit.type === "INCLUSION" || limit.type === "EXCLUSION") {
      incExcLimits.push(limit);
    } else {
      otherLimits.push(limit);
    }
  });

  // If 2D label is scene-stateable, show disabled message
  if (props.data.isSceneStateable) {
    return (
      <div>
        <h3>Disabled</h3>
        <p>Limits only work on Screen labels that are not "SceneState-able"</p>
        <p>
          If you wish to use limits you will need to disable the scene state
          option in the header
        </p>
        <p>Click the green box icon</p>
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          lineHeight: "30px",
          top: "0px",
          position: "absolute",
          right: "70px",
          fontSize: "13px",
          textDecoration: "underline",
        }}
        onClick={props.data.closeLimitsEditor}
      >
        Return
      </div>
      <InclusionExclusionLimitTable
        limits={incExcLimits}
        handleDelete={handleDelete}
        handleValue={handleValue}
        handleOther={handleOther}
      />
      <OtherLimitTable
        limits={otherLimits}
        handleValue={handleValue}
        handleDelete={handleDelete}
      />
      <div style={{ display: "flex" }}>
        <select
          value={selectedOption}
          onChange={(e) => {
            updateSelected(e.target.value);
          }}
        >
          <option value="INCLUSION">INCLUSION</option>
          <option value="EXCLUSION">EXCLUSION</option>
          <option value="ABOVE_ALTITUDE">ABOVE_ALTITUDE</option>
          <option value="BELOW_ALTITUDE">BELOW_ALTITUDE</option>
          <option value="DISTANCE">DISTANCE</option>
        </select>
        <div className="WorldEditorButton" onClick={addLimit}>
          Add Limit
        </div>
      </div>
    </div>
  );
}

function InclusionExclusionLimitTable(props) {
  return (
    <div className="LimitTableContainer">
      Inclusion/Exclusion Limits
      <table className="LightsEditorContainer">
        <thead>
          <tr className="CSSEditorRow">
            <th>Attribute</th>
            <th>Value</th>
            <th>Radius</th>
            <th>Debug</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {props.limits.map((limit, index) => (
            <InclusionLimitRow
              handleValue={props.handleValue}
              handleOther={props.handleOther}
              handleDelete={props.handleDelete}
              key={index}
              {...limit}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function OtherLimitTable(props) {
  return (
    <div className="LimitTableContainer">
      Other Limits
      <table className="LightsEditorContainer">
        <thead>
          <tr className="CSSEditorRow">
            <th>Attribute</th>
            <th>Value</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {props.limits.map((limit, index) => (
            <OtherLimitRow
              handleValue={props.handleValue}
              handleDelete={props.handleDelete}
              key={index}
              {...limit}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

function InclusionLimitRow(props) {
  let value = props.value;
  let otherValue = "N/A";

  if (props.type === "INCLUSION" || props.type === "EXCLUSION") {
    value = "Set Position";
    otherValue = props.value.d;
  }

  const createDebug = () => {
    Inventum.labels.showLimitBounds(props.value);
  };

  return (
    <tr className="CSSEditorRow">
      <td>{props.type}</td>
      <td
        className="WorldEditorButton"
        title={JSON.stringify(props.value)}
        onClick={() => {
          props.handleValue(props.limitID);
        }}
      >
        {value}
      </td>
      <td
        className="WorldEditorButton"
        onClick={() => {
          props.handleOther(props.limitID);
        }}
      >
        {otherValue}
      </td>
      <td className="WorldEditorButton" onClick={createDebug}>
        Show
      </td>
      <td
        className="WorldEditorButton"
        onClick={() => {
          props.handleDelete(props.limitID);
        }}
      >
        Delete
      </td>
    </tr>
  );
}

function OtherLimitRow(props) {
  let value = props.value;
  if (props.type === "INCLUSION" || props.type === "EXCLUSION") {
    value = "Set Position";
  }

  return (
    <tr className="CSSEditorRow">
      <td>{props.type}</td>
      <td
        className="WorldEditorButton"
        title={JSON.stringify(props.value)}
        onClick={() => {
          props.handleValue(props.limitID);
        }}
      >
        {value}
      </td>
      <td
        className="WorldEditorButton"
        onClick={() => {
          props.handleDelete(props.limitID);
        }}
      >
        Delete
      </td>
    </tr>
  );
}

function LimitsPanel(props) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div
        className="EditorDrawerItemHeader"
        onClick={() => setOpen(!open)}
      >
        <span className="PanelOpenStatus">{open ? "▾" : "▸"}</span>
        <span>Limits</span>
      </div>
      {open ? (
        <LimitsEditor
          data={{ ...props, closeLimitsEditor: () => setOpen(false) }}
        />
      ) : null}
    </div>
  );
}

export { LimitsPanel };
