import React, {useState, useEffect, useRef} from 'react';
import { Button, IconButton, Box, CircularProgress } from "@mui/material";
import MaterialTransformInput from "../../../Reusable/NumberInputs/MaterialTransformInput.js";
import MaterialPropertiesInput from '../../../Reusable/NumberInputs/MaterialPropertiesInput.js';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styled } from "@mui/material/styles";
import { ChromePicker } from 'react-color';
import axios from '../../../../../axios-config.js';

function ModelProperties (props) {
  let showAdvanced = false;

  if (props.siteAdmin && props.editMode) {
    showAdvanced = true;
  }

  return (
    <div style={{width:'100%', background:'#383838', paddingBottom: '5px'}} onClick={(e) => {e.stopPropagation()}}>
      <Panel title='Material' initialOpen={true}><MaterialDrawer activeProjectID={props.activeProjectID} showAdvanced={showAdvanced} id={props.id} material={props.modelProps.material} /></Panel>
      { showAdvanced ? <Panel title='Transform' initialOpen={false}><TransformDrawer id={props.id} scale={props.modelProps.scale} position={props.modelProps.position} rotation={props.modelProps.rotation} transformActive={props.modelProps.transformActive} /></Panel> : null}
    </div>
  )
}

function Panel (props) {
  const [open, setOpen] = useState(props.initialOpen);
  return (
    <div>
      <div className='ModelPropsPanelHeader' onClick={(e) => {e.stopPropagation(); setOpen(!open)}}>
        <i className="material-icons notranslate">{open ? 'keyboard_arrow_down' : 'keyboard_arrow_right'}</i>
        <span style={{fontSize: '14px'}}>{props.title}</span>
      </div>
      {open ? props.children : null}
    </div>
  )
}

function MaterialDrawer (props) {
  const material = props.material;
  const showAdvanced = props.showAdvanced;
  const setColor = (color) => { Inventum.models.setMaterial(props.id, {color}) };
  const setEmissive = (emissive) => { Inventum.models.setMaterial(props.id, {emissive}) };
  const setOpacity = (opacity) => { Inventum.models.setMaterial(props.id, {opacity: opacity / 100}) };
  const setRoughness = (roughness) => { Inventum.models.setMaterial(props.id, {roughness: roughness / 100}) };
  const setMetalness = (metalness) => { Inventum.models.setMaterial(props.id, {metalness: metalness / 100}) };
  const setSide = (side) => { Inventum.models.setMaterial(props.id, {side})};
  const setFlat = (flatShading) => { Inventum.models.setMaterial(props.id, {flatShading})};
  const setWireframe = (wireframe) => { Inventum.models.setMaterial(props.id, {wireframe})};

  return (
    <div style={{marginLeft:'5px', fontSize: '14px', marginBottom: '10px'}}>
      {material.hasOwnProperty('color') ? <ColorInput title='Color' color={material.color} onChange={setColor} /> : null}
      {(showAdvanced && material.hasOwnProperty('emissive')) ? <ColorInput title='Emissive' color={material.emissive} onChange={setEmissive} /> : null}
      {material.hasOwnProperty('opacity') ? <MaterialPropertiesInput title='Opacity' value={material.opacity * 100} onChange={setOpacity} /> : null}
      {material.hasOwnProperty('flatShading') ? <Checkbox title='Wireframe' value={material.wireframe} onChange={setWireframe} /> : null}
      {(showAdvanced && material.hasOwnProperty('metalness')) ? <MaterialPropertiesInput title='Metalness' value={material.metalness * 100} onChange={setMetalness} /> : null}
      {(showAdvanced && material.hasOwnProperty('roughness')) ? <MaterialPropertiesInput title='Roughness' value={material.roughness * 100} onChange={setRoughness} /> : null}
      {(showAdvanced && material.hasOwnProperty('side')) ? <Dropdown title='Side' items={['DoubleSide','FrontSide','BackSide']} value={material.side} onChange={setSide} /> : null}
      {(showAdvanced && material.hasOwnProperty('flatShading')) ? <Checkbox title='FlatShading' value={material.flatShading} onChange={setFlat} /> : null}
      {showAdvanced ? <ImageSelector activeProjectID={props.activeProjectID} imageURL={material.mapPath} id={props.id} /> : null}
    </div>
  );
  //      {material.hasOwnProperty('type') ? <Dropdown title='Type' items={['DoubleSide','FrontSide','BackSide']} value={material.type} onChange={() => {}} /> : null}
}

function TransformDrawer (props) {
	if (props.transformActive) {
		return (
			<div onClick={() => { Inventum.models.transformModel(props.id); }} style={{padding: '5px', backgroundColor: '#A32929', textAlign: 'center'}}>
				Disable Gizmo to edit.
			</div>
			)
	}
  const handlePosition = (result) => {Inventum.models.transformModelNumeric(props.id, {position: result});};
  const handleRotation = (result) => {Inventum.models.transformModelNumeric(props.id, {rotation: result});};
  const handleScale = (result) => {Inventum.models.transformModelNumeric(props.id, {scale: result});};
  const resetPosition = () => {Inventum.models.resetPosition(props.id);};
  const resetRotation = () => {Inventum.models.resetRotation(props.id);};
  const resetScale = () => {Inventum.models.resetScale(props.id);};

	// WARNING: Note that the value and valueKey has a non standard order as (X Z Y).
	// The label key is unchanged. This is to label three.js Y as "Z" in the UI display
  return (
    <div style={{marginLeft:'5px', marginTop: '10px'}}>
      <Vec3Input label={['Position', 'X', 'Y', 'Z']} value={[props.position.x, props.position.z, props.position.y]} valueKey={['x', 'z', 'y']} onChange={handlePosition} reset={resetPosition} />
      <Vec3Input label={['Rotation', 'X', 'Y', 'Z']} value={[props.rotation.x, props.rotation.z, props.rotation.y]} valueKey={['x', 'z', 'y']} onChange={handleRotation} reset={resetRotation} />
      <Vec3Input label={['Scale', 'X', 'Y', 'Z']} value={[props.scale.x, props.scale.z, props.scale.y]} valueKey={['x', 'z', 'y']} onChange={handleScale} step={0.01} reset={resetScale} />
    </div>
  )
}

function Vec3Input (props) {
  const [x, setX] = useState(props.value[0] || 0);
  const [y, setY] = useState(props.value[1] || 0);
  const [z, setZ] = useState(props.value[2] || 0);
  const funcMap = {x: setX, y: setY, z: setZ};
  const keyMap = ['x', 'y', 'z'];

  useEffect(() => {
    if (props.value[0] !== x) setX(props.value[0]);
    if (props.value[1] !== y) setY(props.value[1]);
    if (props.value[2] !== z) setZ(props.value[2]);
  }, [props.value]);


  const handleChange = (key, value) => {
    funcMap[key](value);
    if (typeof(props.onChange) !== 'function') return;
    const result = {};
    result[props.valueKey[0]] = x;
    result[props.valueKey[1]] = y;
    result[props.valueKey[2]] = z;

    let keyIndex = keyMap.indexOf(key);
    result[props.valueKey[keyIndex]] = value;
    props.onChange(result);
  }

  return (
    <div style={{ display: "flex", paddingBottom: "10px", alignItems: "center" }}>
      <span style={{ width: "55px", paddingRight: "5px", fontSize: "14px" }}>{props.label[0]}</span>
      <MaterialTransformInput label={props.label[1]} value={x} valueKey={"x"} onChange={handleChange} step={props.step} />
      <MaterialTransformInput label={props.label[2]} value={y} valueKey={"y"} onChange={handleChange} step={props.step} />
      <MaterialTransformInput label={props.label[3]} value={z} valueKey={"z"} onChange={handleChange} step={props.step} />
      <div className="Vec3ResetButton" onClick={props.reset}>
        Reset
      </div>
    </div>
  );
}

function ColorInput (props) {
  const [open, setOpen] = useState(false);
  return (
    <div style={{display: 'flex', alignItems: 'center', marginTop: '10px'}}>
      <span style={{width: '65px'}}>{props.title}</span>
      <div className='ColorValueCell' style={{backgroundColor:props.color, minWidth:'60px'}} onClick={() => {setOpen(true)}}>{open ? <ColorSelector color={props.color} onChange={props.onChange} close={() => {setOpen(false)}} /> : null}</div>
    </div>
  )
}

function ColorSelector (props) {
  return (
    <div>
      <ChromePicker onChange={(e) => {props.onChange(e.hex)}} color={props.color} />
      <div className='ColorCloseButton' onClick={(e) => {e.stopPropagation(); props.close()}}>Close</div>
    </div>
  )
}

function Dropdown (props) {
  const handleChange = (e) => {
    if (typeof(props.onChange) === 'function') {
      props.onChange(e.target.value);
    }
  };
  return (
    <div style={{display: 'flex', marginTop:'10px'}}>
      <div>{props.title}</div>
      <div style={{marginLeft: '10px'}}>
        <select value={props.value} onChange={handleChange} style={{padding: '2px 2px'}}>
          {props.items.map((item,count) => <option key={count} value={item}> {item} </option>)}
        </select>
      </div>
    </div>
  )
}

function Checkbox (props) {
  const handleChange = (e) => {
    if (typeof(props.onChange) === 'function') {
      props.onChange(e.target.checked);
    }
  };
  return (
    <div style={{display: 'flex', marginTop:'10px'}}>
    <div>{props.title}</div>
    <div style={{marginLeft:'10px'}}>
      <input name={props.title} type="checkbox" checked={props.value} onChange={handleChange}/>
    </div>
    </div>
  )
}


const StyledInput = styled("input")({
  display: "none",
});

const smallButtonStyle = {
  padding: "0.05rem 0.2rem",
  fontSize: "0.625rem",
  marginRight: "0.3rem",
};

function ImageSelector(props) {
  const [originalFileName, setOriginalFileName] = useState("");
  const [showFileName, setShowFileName] = useState(false);
  const fileInputRef = useRef(null);
  console.log("props", props);

  useEffect(() => {
    if (props.imageURL) {
      const assetIdentifier = props.imageURL.split("/").pop();
      axios
        .get(`https://api.inventum3d.com/upload/original-filename/${assetIdentifier}`)
        .then((response) => {
          setOriginalFileName(response.data.originalFileName);
          setShowFileName(originalFileName !== "");
        })
        .catch((error) => {
          console.error("Error fetching original filename:", error);
        });
    }
  }, [props.imageURL]);

  const [isLoading, setIsLoading] = useState(false);
  const onFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setShowFileName(false);
      setIsLoading(true);
      setOriginalFileName(file.name);
      const form = new FormData();
      form.append("asset", file);
      form.append("projectID", props.activeProjectID);
      axios
        .post(`https://api.inventum3D.com/upload/`, form)
        .then((response) => {
          if (response.data.url) {
            Inventum.models.setTexture(props.id, response.data.url);
            if (props.onImageUpload) {
              props.onImageUpload(response.data.url);
            }
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Upload error:", error);
          setIsLoading(false);
        });
    }
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(originalFileName)
      .catch((error) => {
        console.error("Copy failed", error);
      });
  };

  const handleRemoveImage = () => {
    Inventum.models.clearTexture(props.id);
    setOriginalFileName("");
    setShowFileName(false);
    if (props.onImageUpload) {
      props.onImageUpload("");
    }
    if (fileInputRef.current) fileInputRef.current.value = "";
  };

  return (
    <Box sx={{ mb: 1, maxWidth: "99%" }}>
      {isLoading && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 200,
          }}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && props.imageURL && (
        <Box
          className="StaticImagePreview"
          sx={{
            backgroundImage: `url(${props.imageURL})`,
            height: 200,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            transition: "height 0.3s ease",
          }}
        />
      )}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mt: 1 }}>
        <Box sx={{ justifyContent: "space-between" }}>
          <label htmlFor="icon-button-file">
            <StyledInput accept="image/*" id="icon-button-file" type="file" onChange={onFileChange} ref={fileInputRef} />
            <Button
              style={smallButtonStyle}
              variant="contained"
              color="secondary"
              onClick={() => fileInputRef.current && fileInputRef.current.click()}>
              Upload a file
            </Button>
          </label>
          <Button style={smallButtonStyle} variant="contained" color="secondary" onClick={() => setShowFileName(!showFileName)}>
            {showFileName ? "Hide file name" : "Show file name"}
          </Button>
        </Box>
        {originalFileName && (
          <Button style={smallButtonStyle} variant="outlined" color="error" onClick={handleRemoveImage}>
            Remove
          </Button>
        )}
      </Box>
      {showFileName && originalFileName && (
        <Box sx={{ display: "flex", alignItems: "center", wordBreak: "break-word" }}>
          <Box sx={{ flexGrow: 1 }}>{originalFileName}</Box>
          <IconButton size="small" onClick={handleCopyToClipboard} sx={{ ml: 1 }}>
            <ContentCopyIcon fontSize="small" color="copy" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

export default ModelProperties;
