import React, { useState } from "react";
import ImagePanel from "./ImagePanel.js";
import { ScalePositionPanel } from "./ScalePositionPanel.js";

function StaticImageLabelHeader(props) {
  const handleClone = (e) => {
    e.stopPropagation();
    Inventum.labels.duplicate(props.id);
    props.getLabels();
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    Inventum.labels.delete(props.id);
    props.getLabels();
  };

  const handleVisible = (e) => {
    e.stopPropagation();
    Inventum.labels.toggleVisible(props.id);
    props.getLabels();
  };

  const handleToggleVisibleStart = (e) => {
    e.stopPropagation();
    Inventum.labels.toggleVisibleStart(props.id);
    props.getLabels();
  };

  const handleNameChange = (e) => {
    props.setLabelName(e.target.value);
  };

  const handleNameBlur = () => {
    props.setIsEditing(false);
  };

  const handleClickEnter = (e) => {
    if (e.key === "Enter") {
      props.setIsEditing(false);
    }
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    props.setIsEditing(true);
  };

  return (
    <div onClick={props.toggleOpen} className="LabelHeader">
      <span className="PanelOpenStatus">{props.isOpen ? "▾" : "▸"}</span>
      <span className="LabelHeaderBtn delete" onClick={handleDelete} title="Delete Label">
        <i className="material-icons notranslate">clear</i>
      </span>
      <span className="LabelHeaderBtn" onClick={handleClone} title="Clone Label">
        <i className="material-icons notranslate">copy_all</i>
      </span>
      <span
        className="LabelHeaderBtn"
        style={{ opacity: props.visibleStart ? 1.0 : 0.4 }}
        onClick={handleToggleVisibleStart}
        title={
          props.visibleStart
            ? "Label will appear when Scene Loads (Visible Start = True)"
            : "Label won't appear when scene loads (Visible Start = False)"
        }
      >
        <i className="material-icons notranslate">
          {props.visibleStart ? "tour" : "not_interested"}
        </i>
      </span>
      <span
        className={"LabelHeaderBtn" + (props.visible ? " active" : "")}
        onClick={handleVisible}
        title="Toggle Visible"
      >
        <i className="material-icons notranslate">
          {props.visible ? "check_box" : "check_box_outline_blank"}
        </i>
      </span>
      <span className="LabelHeaderBtn" onClick={handleEditClick} title="Change Label Name">
        <i className="material-icons notranslate">edit</i>
      </span>
      {props.isEditing ? (
        <input
          type="text"
          value={props.labelName}
          onChange={handleNameChange}
          onBlur={handleNameBlur}
          onKeyDown={handleClickEnter}
          autoFocus
        />
      ) : (
        <span>{props.labelName}</span>
      )}
    </div>
  );
}

function StaticImageLabelDrawer(props) {
  const onImageLoad = (url) => {
    // When image is loaded, call setStaticImage, then refresh labels
    Inventum.labels.setStaticImage(props.id, url, () => {
      props.getLabels();
    });
  };

  return (
    <div style={{ padding: "5px" }}>
      <ImagePanel url={props.imageURL} onImageLoad={onImageLoad} {...props} />
      <ScalePositionPanel {...props} />
    </div>
  );
}

function StaticImageLabel(props) {
  // Pull current label name from the store
  const initialLabelName = Inventum.labels.getLabelName(props.id) || "Default Label Name";

  const [open, setOpen] = useState(false);
  const [labelName, setLabelName] = useState(initialLabelName);
  const [isEditing, setIsEditing] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  // When updating label name in state, also update in Inventum
  const handleSetLabelName = (name) => {
    setLabelName(name);
    Inventum.labels.updateLabelName(props.id, name);
  };

  return (
    <div>
      <StaticImageLabelHeader
        toggleOpen={toggleOpen}
        labelName={labelName}
        isEditing={isEditing}
        setLabelName={handleSetLabelName}
        setIsEditing={setIsEditing}
        isOpen={open}
        {...props}
      />
      <div style={{ marginLeft: "5px", backgroundColor: "#303030" }}>
        {open ? <StaticImageLabelDrawer {...props} /> : null}
      </div>
    </div>
  );
}

export { StaticImageLabel };
