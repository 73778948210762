import React, { useState, useEffect, useRef } from "react";
import { Button, Box, CircularProgress, IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styled } from "@mui/material/styles";
import axios from "../../../../../axios-config.js";
import { connect } from "react-redux";

function ImagePanel(props) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div
        className="EditorDrawerItemHeader"
        onClick={() => setOpen(!open)}
      >
        <span className="PanelOpenStatus">{open ? "▾" : "▸"}</span>
        <span>Image</span>
      </div>
      {open && (
        <ImageDrawer
          url={props.url}
          onImageLoad={props.onImageLoad}
          activeProjectID={props.activeProjectID}
          {...props}
        />
      )}
    </div>
  );
}

const StyledInput = styled("input")({
  display: "none",
});

const smallButtonStyle = {
  padding: "0.05rem 0.2rem",
  fontSize: "0.625rem",
  marginRight: "0.3rem",
};

function ImageDrawer(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [originalFileName, setOriginalFileName] = useState("");
  const [showFileName, setShowFileName] = useState(false);

  // We add this state to track if the file dialog is open
  const [isFileDialogOpen, setIsFileDialogOpen] = useState(false);

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (props.url) {
      const assetIdentifier = props.url.split("/").pop();
      setIsLoading(true);
      axios
        .get(`https://api.inventum3d.com/upload/original-filename/${assetIdentifier}`)
        .then((response) => {
          setOriginalFileName(response.data.originalFileName);
          setShowFileName(response.data.originalFileName !== "");
        })
        .catch((error) => {
          console.error("Error fetching original filename:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [props.url]);

  const onFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setShowFileName(false);
      setIsLoading(true);
      setOriginalFileName(file.name);
      const form = new FormData();
      form.append("asset", file);
      form.append("projectID", props.activeProjectID);
      axios
        .post("https://api.inventum3D.com/upload/", form)
        .then((response) => {
          if (response.data.url) {
            props.onImageLoad(response.data.url);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Upload error:", error);
          setIsLoading(false);
        });
    }
    // Reset file input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    // Once a file is selected or canceled, close the dialog
    setIsFileDialogOpen(false);
  };

  const handleUploadClick = (e) => {
    e.stopPropagation();
    if (!isFileDialogOpen) {
      setIsFileDialogOpen(true);
      fileInputRef.current?.click();
    }
  };

  const handleRemoveImage = () => {
    setOriginalFileName("");
    setShowFileName(false);
    props.onImageLoad("");
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(originalFileName).catch((error) => {
      console.error("Copy to clipboard failed:", error);
    });
  };

  return (
    <Box sx={{ mb: 1, maxWidth: "99%" }}>
      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 200 }}>
          <CircularProgress />
        </Box>
      )}

      {!isLoading && props.url && (
        <Box
          className="StaticImagePreview"
          sx={{
            backgroundImage: `url(${props.url})`,
            height: 200,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            transition: "height 0.3s ease",
          }}
        />
      )}

      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mt: 1 }}>
        <Box sx={{ justifyContent: "space-between" }}>
          <label htmlFor="icon-button-file">
            <StyledInput
              accept="image/*"
              id="icon-button-file"
              type="file"
              onChange={onFileChange}
              ref={fileInputRef}
            />
            <Button
              style={smallButtonStyle}
              variant="contained"
              color="secondary"
              component="span"
              onClick={handleUploadClick}
              disabled={isLoading}
            >
              Upload a file
            </Button>
          </label>

          {originalFileName && (
            <Button
              style={smallButtonStyle}
              variant="contained"
              color="secondary"
              onClick={() => setShowFileName(!showFileName)}
            >
              {showFileName ? "Hide file name" : "Show file name"}
            </Button>
          )}
        </Box>

        {originalFileName && (
          <Button
            style={smallButtonStyle}
            variant="outlined"
            color="error"
            onClick={handleRemoveImage}
          >
            Remove
          </Button>
        )}
      </Box>

      {showFileName && originalFileName && (
        <Box sx={{ display: "flex", alignItems: "center", wordBreak: "break-word" }}>
          <Box sx={{ flexGrow: 1 }}>{originalFileName}</Box>
          <IconButton size="small" onClick={handleCopyToClipboard} sx={{ ml: 1 }}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

// Redux mapping
const mapStateToProps = (state) => ({
  activeProjectID: state.ui.activeProject,
});

// If you don't need to dispatch anything, you can omit mapDispatchToProps
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ImagePanel);
