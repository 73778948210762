import React from 'react';
import {connect} from 'react-redux';
import {toggleDrawingPanel} from '../../../Redux/actions/ui.js';

class ViewerControls extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalID:null,
      cameraDirection:0,
      rulerEnabled:false,
      areaModeEnabled:false,
			isRecording: false
    };
    this.getCameraDirection = this.getCameraDirection.bind(this);
  }

  componentDidMount() {
    let intervalID = setInterval(() => {
      this.getCameraDirection();
    },500);
    this.setState({intervalID})
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalID);
  }

  getCameraDirection() {
    let angle = Inventum.camera.getCameraYAngle().toFixed(0);
    if (angle == this.state.cameraDirection) {
      return;
    }
    this.setState({cameraDirection:Inventum.camera.getCameraYAngle()})
  }

  render() {
    let captureScreenshot = () => {
      Inventum.screenshot.capture();
    }

    let toggleMarkers = () => {
      Inventum.markers.toggleAll();
    }

    let toggle3DView = () => {
      Inventum.world.showVisibleStart();
      Inventum.camera.showStartView();
    }

    let toggleHighlight = () => {
      console.log('Highlight not implemented');
    }

    let toggleFullscreen = () => {
      let el = document.documentElement;
      if (!document.fullscreenElement) {
        if (el.requestFullscreen) {
          el.requestFullscreen()
          screen.orientation.lock("landscape");
        }
      }else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
          screen.orientation.unlock()
        }
      }
    }

		const toggleRecording = () => {
			if (!this.state.isRecording) {
				Inventum.video.startRecording();
			}else {
				Inventum.video.stopRecording(this.props.pageTitle);
			}
			this.setState({ isRecording: !this.state.isRecording })
		};

	  const handleRuler = () => {
	    this.setState({rulerEnabled:Inventum.ruler.toggle()});
	  };

	  const handleArea = () => {
	    this.setState({areaModeEnabled:Inventum.ruler.togglePlanar()});
	  };

		return (
      <div className={"map-vertical-controls" + (this.props.uiHideMode === 'ALL' || this.props.uiHideMode === 'ANIMATION' ? ' hidden' : '')}>
        <div className="map-vertical-button" title={"Return to 3D view"} onClick={toggle3DView}><i className="material-icons notranslate">3d_rotation</i></div>
        <div style={{fontSize:"12px",color:"#FFF",fontFamily:"Sans-Serif"}} className="map-vertical-button" onClick={Inventum.camera.showTopNorth} title={"North Arrow"}><i style={{transition:"none", transform:`rotate(${this.state.cameraDirection}deg)`}} className="material-icons notranslate">arrow_upward</i>N</div>
        {/* <PenToolButton onClick={() => this.props.toggleDrawingPanel()} /> */}
        <RulerButton areaModeEnabled={this.state.areaModeEnabled} handleArea={handleArea} rulerEnabled={this.state.rulerEnabled} handleClick={handleRuler} />
        <div className="map-vertical-button" title={"Toggle visibility of markers"} onClick={toggleMarkers}><i className="material-icons notranslate">bubble_chart</i></div>
				<div className="map-vertical-button" title={"Take a Screenshot"} onClick={captureScreenshot}><i className="material-icons notranslate">photo</i></div>
				{this.props.siteAdmin ? <div style={this.state.isRecording ? {backgroundColor: "#F00"} : {}} className="map-vertical-button" title={"Admin Only Beta: Toggle Video Recording"} onClick={toggleRecording}><i className="material-icons notranslate">movie_creation</i></div> : null }
				<div className="map-vertical-button" title={"Toggle Fullscreen"} onClick={toggleFullscreen}><i className="material-icons notranslate">{document.fullscreenElement ? "fullscreen_exit" : "fullscreen"}</i></div>
      </div>
    )
  }
}

function PenToolButton({ onClick }) {
  return (
    <div className="map-vertical-button" title="Toggle Drawing Mode" onClick={onClick}>
      <i className="material-icons notranslate">edit</i>
    </div>
  );
}


function RulerButton(props) {
  return (
    <div className="map-vertical-button-group">
      <div className={"map-vertical-button" + (props.rulerEnabled ? " active" : "")} title={"Toggle Ruler Mode \n* Left Click Create Point\n* Right Click End Ruler"} onClick={props.handleClick}><i className="material-icons notranslate">straighten</i></div>
      {props.rulerEnabled ? <div className={"map-vertical-button" + (props.areaModeEnabled ? " active" : "")}title={"Measure Area"} onClick={props.handleArea} style={{position:"absolute",right:"55px",top:"0px"}}><i className="material-icons notranslate">texture</i></div> : null}
      {props.rulerEnabled ? <div className="map-vertical-button" title={"Clear Rulers"} onClick={Inventum.ruler.clearAll} style={{position:"absolute",right:"110px",top:"0px"}}><i className="material-icons notranslate">delete</i></div> : null}
    </div>
  )
}

let mapStateToProps = (state) => ({
  uiHideMode: state.ui.hideMode,
  siteAdmin: (state.user.siteAdmin) ? true : false,
	pageTitle: state.sidebarUI.title
})

let mapDispatchToProps = dispatch => ({
	toggleDrawingPanel: () => {
    dispatch(toggleDrawingPanel())
  }
});

const ViewerControlsRedux = connect(mapStateToProps, mapDispatchToProps)(ViewerControls);


export default ViewerControlsRedux;
